import React, {Component} from 'react';
import { Container, Row, Col, Button, Table} from 'reactstrap';
import OdysseyCarousel from './OdysseyCarousel';

class Odyssey extends Component{
    render(){
        return(
            <div>
            <h1>Odyssey Commerce </h1>
              
              <p>Small Business Marketplace </p>
              <img src={window.location.origin + '/img/odyssey/logo.png'} alt='odyssey logo' style={{ height: '30px'}}/>
              <a target="blank" style={{fontSize: '32px', paddingBottom: '32px', display: 'block', margin: 'auto'}}href="https://docs.google.com/presentation/d/1BIHTQVKxzBQC-uooAyuNYXr5B6U1PsqeqImSLjwO41A/edit?usp=sharing">OdysseyCommerce Pitch Deck</a>
                <span style={{ marginLeft: '20px'}}>My Covid Project. Built with Mitch, Yue and Tony during an uncertain time. A huge thank you to them for participating.</span>
                <div style={{display: 'flex'}}>
              <a target="blank" style={{fontSize: '16px', paddingBottom: '32px', display: 'inline-block', margin: 'auto'}}href="https://www.loom.com/share/e728a1c3df7e4163bca4195a4a1471e4">OdysseyCommerce Overview Video</a>
              <a target="blank" style={{fontSize: '16px', paddingBottom: '32px', display: 'inline-block', margin: 'auto'}}href="https://www.loom.com/share/b21fe6746bb845d5a6d24231bdbea4bd">Merchant Dashboard Video</a>
              </div>
              <OdysseyCarousel/>
              <a target="blank" style={{fontSize: '32px', paddingBottom: '32px', display: 'block', margin: 'auto'}}href="https://www.figma.com/proto/UgeopVJYUhWNdZyTaSmSsZ/Odyssey---Website-Design?node-id=3344-36086&starting-point-node-id=3344%3A36086&scaling=min-zoom">Figma Design</a>
                <span style={{ marginLeft: '20px'}}>Designed by: <a href="mailto:zhaoyue777@gmail.com">Yue Zhao</a></span>
                <Table dark>
                    <tbody>
                    <tr>
                        <td> Languages: </td>
                        <td>Golang</td>
                        <td>JavaScript</td>
                        <td>Python</td>
                    </tr>
                    <tr>
                        <td>Technologies: </td>
                        <td>PostGis</td>
                        <td>OpenSource Maps</td>
                        <td>BeautifulSoup</td>
                    </tr>
                    <tr>
                        <td>3rd Party Services & Libraries:  </td>
                        <td>Stripe</td>
                        <td>Auth0</td>
                        <td>Shopify (for scraping)</td>
                    </tr>
                    </tbody>
                </Table>
              {/* <Container style={{marginBottom: '25px'}}>
                <Row>
                  <Col>
                    <a target="__blank" href="https://github.com/crescit/mealMe">
                      <Button className={"visitButton"} block style={{
                        backgroundColor: '#008080',
                        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                        translateY: 30,
                      }}>
                        View Repository
                      </Button>
                    </a>
                  </Col>
                  <Col>
                    <a target="__blank" href="https://mealme2018.herokuapp.com">
                      <Button className={"visitButton"} block style={{
                        backgroundColor: '#008080',
                        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                        translateY: 30,
                      }}>
                        Visit mealMe
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Container> */}
            </div>
        )
    }
}
export default Odyssey;
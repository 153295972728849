import React, {Component} from 'react';
import {Container, Row, Col, ListGroup, ListGroupItem} from 'reactstrap';
import Footer from "./Footer";

class DesignWork extends Component {
    render() {
        return (
            <div  style={{
              'marginRight': '6px',
              'marginLeft': '6px',
              'borderRadius': '16px',
              'backgroundColor': 'white',
              'textAlign': 'center',
              'fontSize': '72'
            }}
                  className={"item"} data-aos="zoom-in"
            >
                <h1 style={{paddingTop: '15px', marginTop: '1.5rem', marginBottom: '1.5rem'}}>Design Work</h1>
                <p> Whether or not your application has a clean and comprehensible user experience, often determines the
                    adoption and success of your program. Due to that fact, the industry is recognizing the importance
                    of designing your application for the end user, and not over-engineering UX. I believe the design
                    process should be influenced by two metrics: frequency-of-use and ease-of-use. As a student in my
                    CS-570 course, I performed contextual inquiries, built work models, affinity diagrams,
                    high-fidelity prototypes, and distributed
                    both usage and diary forms to gather information on and improve two applications: LinkedIn and
                    Facebook's Messenger. </p>
                <h3>Issues Found</h3>
                <Container>
                    <Row>
                        <Col size="md">LinkedIn
                            <ListGroup>
                                <ListGroupItem className="text-dark">"Search For" section in search bar confused users
                                    over what behavior would occur on search</ListGroupItem>
                                <ListGroupItem className="text-dark">Modals could lead to undesired behavior, such as
                                    redirection, when editing user skills </ListGroupItem>
                                <ListGroupItem className="text-dark">Modals interrupted the user's flow when editing
                                    skills</ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col size="md">Facebook Messenger
                            <ListGroup>
                                <ListGroupItem className="text-dark">Frequent Crashes and Over-Complication of the
                                    primary service</ListGroupItem>
                                <ListGroupItem className="text-dark">Over-Engineered UX crammed with features that
                                    almost none of our participants were aware of or knew how to use</ListGroupItem>
                                <ListGroupItem className="text-dark">Inadequate Search Functionality</ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <br/>
                <h3>Findings</h3>
                <Container>
                    <Row>
                        <Col size="md">LinkedIn
                            <ListGroup>
                                <ListGroupItem className="text-dark"> Putting the 'Search For' section in a separate drop-down box allowed users to more easily understand that the section worked as a filter</ListGroupItem>
                                <ListGroupItem className="text-dark">Removing unnecessary modal boxes simplified the UX and allowed the user to perform their desired actions in an easier and faster manner </ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col size="md">Facebook Messenger
                            <ListGroup>
                                <ListGroupItem className="text-dark">Searching by Category performed on average two seconds faster than other methods tested</ListGroupItem>
                                <ListGroupItem className="text-dark">Stream-lining the home page to just a search bar, chats, and a navigation bar on the bottom allowed for a less convoluted design than the application previously allowed</ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <br/>
                <h3>Results</h3>
                <p>Approximately two week after the contextual inquiry for Facebook Messenger was submitted, an update for the Facebook Messenger app had implemented many of the designs we thought could have improved it. Facebook had also most likely identified the same issues.  </p>
                <br/>
                <h6 style={{marginBottom: '2rem'}}>Write Ups Available Upon Request</h6>
                <div id='footer-wrapper' style={{ paddingBottom: '2rem' }}>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default DesignWork;
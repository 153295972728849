import React, {Component} from 'react';
// import me from '../image/me2.png';
import {Container, Col, Row} from 'reactstrap';
class About extends Component {
//<img alt="the author of this page" style={{width: '220px', height: '220 px', borderRadius: '20px'}} src={window.location.origin + '/img/shot.jpg'}/>

    render() {
        return (<div className="about" style={{marginBottom: '20px'}}>
            <h1
              style={{
              'paddingTop': '15px',
                'marginBottom': '1.2rem',
              fontSize: '1.9rem', opacity:0.75, fontFamily: 'IBM Plex Sans',
            }}>I live and breath start-ups.</h1>
          <Container>
            <Row>
              <Col lg={'auto'}>
          {/* <img alt="the author of this page" style={{width: '19rem', height: '18rem', borderRadius: '20px'}} src={me}/> */}
              </Col>


              <Col lg={6}>
                <ul style={{'list-style-type': 'none', left: '2rem', alignText: 'right'}}>
                  <div className={"item"}>
                    <li id={'aboutItem'}> </li>
                  </div>
                </ul>
                </Col>
            </Row>
          </Container>
        </div>)
    }
}

export default About;
import React, {Component} from 'react';
import About from "./About";
import Aggregio from "./Aggregio";
import MealMe from "./MealMe";
import DJsBakery from "./DJsBakery";
import Footer from "./Footer";
import Odyssey from './Odyssey';

class Main extends Component{

    render() {
      let renderedContent = <div>
        {/* <div className={"item"} data-aos="fade-out" id="about" style={{paddingBottom: '15px', marginBottom: '15px' }}>
          <About/>
        </div> */}
        <div className={"item"} data-aos="fade-in" style={{marginTop: '50px', marginBottom: '50px'}}>
          <h2 style={{fontStyle: 'italic', fontSize: '2.1rem', opacity:0.5, fontFamily: 'IBM Plex Sans',}}> Projects </h2>
        </div>
        <div className={"item"} data-aos="fade-up" id="odyssey" style={{'paddingBottom': '15px' }}>
          <Odyssey/>
        </div>
        <h3 style={{fontStyle: 'italic', fontSize: '1.8rem', opacity:0.5, fontFamily: 'IBM Plex Sans', marginTop: '20px', marginBottom: '20px'}}> Pre 2020 Projects </h3>
        <div className={"item"} data-aos="fade-up" id="mealMe" style={{'paddingBottom': '15px' }}>
          <MealMe/>
        </div>
        <div className={"item"} data-aos="fade-left" id="aggregio" style={{'paddingBottom': '15px' }}>
          <Aggregio/>
        </div>
        <div className={"item"} data-aos="zoom-in"  id="djs" style={{'paddingBottom': '15px' }}>
          <DJsBakery/>
        </div>
        <Footer style={{paddingBottom: '15px',
          marginBottom: '25px'}}/>
      </div>;
        return(
            <div style={{
              'marginRight': '2px',
              'marginLeft': '2px',
              'paddingBottom': '15px',
              'borderRadius': '10px',
              'backgroundColor': 'white',
              'textAlign': 'center',
              'fontSize': '72',
              'opacity': '0.94',
              fontFamily: 'IBM Plex Sans',

            }}>
              {renderedContent}

            </div>)
    }
}
export default Main;
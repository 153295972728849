import React, { Component } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NavigationBar from './components/NavigationBar'
import Main from './components/Main';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DesignWork from "./components/DesignWork";
import jj from './image/jj.jpeg';
class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      alreadyRendered: false
    };





    AOS.init();

// You can also pass an optional settings object
// below listed default settings
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 40, // offset (in px) from the original trigger point
      delay: 20, // values from 0 to 3000, with step 50ms
      duration: 1200, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

    });
  }
  componentDidMount(){
    setTimeout(() => {
      this.setState({alreadyRendered: true});
    },570);
  }
  render() {
    let content = <div  style={
      {
        'height': '1000px',
        'backgroundImage': 'linear-gradient(to right, #212121, #212121)',
      }}>

      <img src={jj} class="rotated" alt="logo" height="356" width="356" style={{
        position: 'absolute',
        top: '10%',
        left: '10%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '17px',
        animationName: 'spin',
        animationDuration: '830ms',
        animationIterationCount: 'infinite',
      }}/>
    </div>;
    let renderedContent = <div>
      <div className="App "
           style={
             {'marginRight': '1px',
               'marginLeft': '1px',
               'backgroundImage': 'linear-gradient(to right, #212121, #212121)'
             }}>
        <NavigationBar rendered={this.state.alreadyRendered}/>
        <Switch>
          <Route exact path="/" component={Main} />
        </Switch>
        <Switch>
          <Route exact path="/design" component={DesignWork} />
        </Switch>
      </div>
    </div>;
    return (
        <Router>
        <div>{!this.state.alreadyRendered && (window.location.href.toString().indexOf('/design') === -1 && window.location.href.toString().indexOf('/resume') === -1 ) ? content: renderedContent}</div>
        </Router>
    );
  }
}

export default App;

import React, {Component} from 'react';
export default class Footer extends Component{
    render(){
        return(
            <div>
                <footer style={{padding: "1px", height: "34px", display: 'center'}}>
                    <div className="container" >
                        <p >
                          <a target={"_blank"}  href="https://github.com/crescit">
                            <i
                            style={{'display': 'inline', 'marginRight': '20px'}}className="fab fa-github"></i>
                          </a>
                          {/* <a style={{'display': 'inline'}} target={"_blank"} href="https://www.linkedin.com/in/josh-jaquez">
                            <i  style={{'display': 'inline', 'marginRight': '20px'}} className="fab fa-linkedin"></i>
                          </a> */}
                        </p>
                    </div>
                </footer>
            </div>
        )
    }
}
import React, {Component} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    } from 'reactstrap';
import { withRouter } from 'react-router'

class NavigationBar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <div className={"item"} data-aos="zoom-out" style={{
              fontFamily: 'IBM Plex Sans',
            }}>
                <Navbar id="navbar" color="#212121" dark expand="md" >
                    <NavbarBrand href="/"></NavbarBrand>
                    <hr/>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink target={"_blank"} href="https://github.com/crescit"><i
                                    className="fab fa-github"></i></NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink target={"_blank"} href="https://www.linkedin.com/in/josh-jaquez"><i
                                  className="fab fa-linkedin"></i></NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink href="/">Main</NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink href="/design">HCI/UX Work from College</NavLink>
                            </NavItem> */}

                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}
export default withRouter(NavigationBar);
import React, {Component} from 'react';
import { Container, Row, Col, Button, Table} from 'reactstrap';
import AggregioCarousel from './AggregioCarousel';
class Aggregio extends Component{
    render(){
        return(<div>
            <h1>Aggregio</h1>
            <AggregioCarousel/>


                <br/>


                <p>Music service aggregator using Spotify’s Web-Play-Back API and Apple’s MusicKit.js. Users are allowed to create playlists and save tracks and albums.</p>

                <Table dark>
                            <tbody>
                            <tr>
                                <td>Languages: </td>
                                <td>JavaScript</td>
                            </tr>
                            <tr>
                                <td>Stack: </td>
                                <td>MERN</td>

                            </tr>
                            <tr>
                                <td>3rd Party Services & Libraries</td>
                                <td>MusicKit.js</td>
                                <td>Spotify Web Api</td>
                            </tr>
                            </tbody>
                        </Table>
{/* 
            <Container>
              <Row>
                <Col>
                  <a target="__blank" href="https://github.com/crescit/Aggregio">
                    <Button className={"visitButton"} block style={{
                      backgroundColor: '#33b5e5',
                      boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                      translateY: 30,
                    }}>
                      View Repository
                    </Button>
                  </a>
                </Col>
                <Col>
                  <a target="__blank" href="https://aggregio.herokuapp.com">
                    <Button className={"visitButton"} block style={{
                      backgroundColor: '#33b5e5',
                      boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                      translateY: 30,
                    }}>
                      Visit Aggregio
                    </Button>
                  </a>
                </Col>
              </Row>
            </Container> */}
                <br/>

            </div>
            )
    }
}
export default Aggregio;
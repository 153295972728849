import React, {Component} from 'react';
import { Container, Row, Col, Button, Table} from 'reactstrap';
import DJCarousel from './DJCarousel';

class DJsBakery extends Component{
    render(){
        return(
            <div>
            <h1>DJ's Bakery</h1>
              <DJCarousel/>

                <br/>
                <p>Bakery website with a custom portal for CMS.</p>
              <Table dark>
                <tbody>
                <tr>
                  <td>Languages: </td>
                  <td>Python</td>
                </tr>
                <tr>
                  <td>Stack: </td>
                  <td>Flask</td>

                </tr>
                <tr>
                  <td>3rd Party Services & Libraries</td>
                  <td>Google Cloud Platform</td>
                </tr>
                </tbody>
              </Table>
              {/* <Container>
                <Row>
                  <Col>
                    <a target="__blank" href="https://github.com/crescit/DJ-s-Bakery">
                      <Button className={"visitButton"} block style={{
                        backgroundColor: '#33b5e5',
                        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                        translateY: 30,
                      }}>
                        View Repository
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Container> */}

                <br/>
            </div>
        )
    }
}
export default DJsBakery;
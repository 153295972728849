import React, {Component} from 'react';
import { Container, Row, Col, Button, Table} from 'reactstrap';
import MealMeCarousel from './MealMeCarousel';

class MealMe extends Component{
    render(){
        return(
            <div>
            <h1>Meal Me</h1>
              <MealMeCarousel/>

              <p>A single page application serving recipes scraped from foodnetwork.com. The application allows users to save recipes, search by ingredients, and add recipe ingredients to their shopping list. </p>

                <Table dark>

                    <tbody>
                    <tr>
                        <td> Languages: </td>
                        <td>JavaScript</td>
                        <td>Python</td>
                    </tr>
                    <tr>
                        <td>Stack: </td>
                        <td>MERN</td>
                    </tr>
                    <tr>
                        <td>3rd Party Services & Libraries:  </td>
                        <td>Firebase</td>
                        <td>Bootstrap</td>
                    </tr>
                    </tbody>
                </Table>
              {/* <Container style={{marginBottom: '25px'}}>
                <Row>
                  <Col>
                    <a target="__blank" href="https://github.com/crescit/mealMe">
                      <Button className={"visitButton"} block style={{
                        backgroundColor: '#008080',
                        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                        translateY: 30,
                      }}>
                        View Repository
                      </Button>
                    </a>
                  </Col>
                  <Col>
                    <a target="__blank" href="https://mealme2018.herokuapp.com">
                      <Button className={"visitButton"} block style={{
                        backgroundColor: '#008080',
                        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(255,255,255,0.19)',
                        translateY: 30,
                      }}>
                        Visit mealMe
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Container> */}
            </div>
        )
    }
}
export default MealMe;